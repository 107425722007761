import axios from "axios";
import { addSessionToken } from "../../utils/axios.utils";
import {
  AUTH,
  ACCOUNT,
  USER,
  INFO,
  UPDATE_USER_INFO,
  LOGIN,
  LOGOUT,
  SIGN_UP,
  VERIFY,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  RESEND_ACCOUNT_VERIFICATION_EMAIL,
  VERIFY_NEW,
  PROFILE_INFO,
  CHANGE_PASSWORD,
  RESEND_VERIFY_NEW_EMAIL_LINK,
  DETAILS,
  PRICE_MARGIN,
  HEALTH,
} from "../../constants/apiLink";
import { logout } from "./auth.reducer";

export const apiLogin = async (data) => {
  const url = AUTH + LOGIN;

  return axios
    .post(url, data)
    .then((result) => {
      console.log(result);
      const { data } = result;
      const { accessToken } = data;
      addSessionToken(accessToken);
      return result;
    })
    .catch((error) => {
      console.log(error.response);
      // Handle error response
      if (error.response.data) {
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
    });
};

export const apiHealthCheck = (async) => {
  const url = HEALTH;

  return axios
    .post(url)
    .then((result) => {
      if (result) return true;
      return false;
    })
    .catch(() => {
      return false;
    });
};

export const apiGetUserInfo = (async) => {
  const url = USER + INFO;
  return axios.get(url).then((result) => {
    return result.data;
  });
};

export const apiSignUp = async (data) => {
  const url = USER + SIGN_UP;
  return axios.post(url, data).then((result) => {
    return result;
  });
};

export const apiLogout = async (id, adminSessionId) => {
  const url = AUTH + LOGOUT;
  // let url = AUTH + "logout?userId=" + id;
  // if (adminSessionId) {
  //   url = url + "&adminSessionId=" + adminSessionId;
  // }
  return axios.post(url).then((result) => {
    return result;
  });
};

export const apiVerifyEmailCode = async (code) => {
  const url = USER + VERIFY + "/" + code;
  return axios.post(url).then((result) => {
    return result;
  });
};

export const apiForgotPassSendEmail = async (body) => {
  const url = USER + FORGOT_PASSWORD;
  return axios.post(url, body).then((result) => {
    return result;
  });
};

export const apiVerifyResetPassCode = async (code, email) => {
  const url = AUTH + RESET_PASSWORD + "?email=" + email + "&token=" + code;
  return axios.get(url).then((result) => {
    return result;
  });
};

export const apiResetPassword = async (body) => {
  const url = USER + RESET_PASSWORD + "/" + body.token;
  return axios.post(url, body).then((result) => {
    return result;
  });
};

export const apiGetProfileDetails = (async) => {
  const url = USER + PROFILE_INFO;
  return axios.get(url).then((result) => {
    return result;
  });
};

export const apiResendAccountVerificationEmail = async (email) => {
  const url = AUTH + RESEND_ACCOUNT_VERIFICATION_EMAIL + "?email=" + email;
  return axios.get(url).then((result) => {
    return result;
  });
};

export const apiUpdateProfileInfo = async (data) => {
  const url = USER + UPDATE_USER_INFO;
  return axios.patch(url, data).then((result) => {
    return result;
  });
};

export const apiUpdateAccountInfo = async (data) => {
  const url = ACCOUNT + DETAILS;
  return axios.put(url, data).then((result) => {
    return result;
  });
};

export const apiUpdatePriceMargin = (data) => {
  const url = ACCOUNT + PRICE_MARGIN;
  return axios.put(url, data).then((result) => {
    return result;
  });
};

export const apiChangePassword = async (body) => {
  const url = USER + CHANGE_PASSWORD;
  return axios.patch(url, body).then((result) => {
    return result;
  });
};

export const apiVerifyNewEmailCode = async (code, email) => {
  const url = AUTH + VERIFY_NEW + "/?token=" + code + "&email=" + email;
  return axios.get(url).then((result) => {
    return result;
  });
};

export const apiResendVerifyNewEmailLink = async () => {
  const url = USER + RESEND_VERIFY_NEW_EMAIL_LINK;
  return axios.get(url).then((result) => {
    return result;
  });
};

export const refreshToken = async () => {
  const refreshToken = window.localStorage.getItem("refreshToken");
  return axios
    .post("/api/Auth/refresh-token", {
      refreshToken,
    })
    .then((response) => {
      if (response) {
        if (response.data) {
          const {
            accessToken,
            accessJobbing,
            refeshToken,
            accountType,
            userType,
            stockLocationName,
            boahubLocationId,
            relatedCompanyLogoUrl,
            relatedCompanyId,
            defaultAssetCategory,
            boahubFindPod,
            boahubIsRS23,
            boahubBuildDigitalTwin,
          } = response.data;
          if (accessToken && refeshToken) {
            addSessionToken(accessToken);
            window.localStorage.setItem("sessionID", accessToken);
            window.localStorage.setItem("accessJobbing", accessJobbing);
            window.localStorage.setItem("refreshToken", refeshToken);
            window.localStorage.setItem("accountType", accountType);
            window.localStorage.setItem("userType", userType);
            window.localStorage.setItem("stockLocationName", stockLocationName);
            window.localStorage.setItem("boahubLocationId", boahubLocationId);
            window.localStorage.setItem(
              "relatedCompanyLogoUrl",
              relatedCompanyLogoUrl
            );
            window.localStorage.setItem("companyId", relatedCompanyId);
            window.localStorage.setItem(
              "defaultAssetCategory",
              defaultAssetCategory
            );
            window.localStorage.setItem("boahubFindPod", boahubFindPod);
            window.localStorage.setItem("boahubIsRS23", boahubIsRS23);
            window.localStorage.setItem(
              "boahubBuildDigitalTwin",
              boahubBuildDigitalTwin
            );

            if (accountType === 1 || accountType === "1") {
              window.localStorage.setItem("accessJobbing", "true");
            } else {
              window.localStorage.setItem("accessJobbing", "false");
            }
          }
        }
      }
    });
};

export const refreshTokenAndReload = async () => {
  const refreshToken = window.localStorage.getItem("refreshToken");
  return axios
    .post("/api/Auth/refresh-token", {
      refreshToken,
    })
    .then((response) => {
      if (response) {
        if (response.data) {
          const {
            accessToken,
            accessJobbing,
            refeshToken,
            accountType,
            userType,
            stockLocationName,
            boahubLocationId,
            relatedCompanyLogoUrl,
            relatedCompanyId,
            defaultAssetCategory,
          } = response.data;
          if (accessToken && refeshToken) {
            addSessionToken(accessToken);
            window.localStorage.setItem("sessionID", accessToken);
            window.localStorage.setItem("accessJobbing", accessJobbing);
            window.localStorage.setItem("refreshToken", refeshToken);
            window.localStorage.setItem("accountType", accountType);
            window.localStorage.setItem("userType", userType);
            window.localStorage.setItem("stockLocationName", stockLocationName);
            window.localStorage.setItem("boahubLocationId", boahubLocationId);
            window.localStorage.setItem(
              "relatedCompanyLogoUrl",
              relatedCompanyLogoUrl
            );
            window.localStorage.setItem("companyId", relatedCompanyId);
            window.localStorage.setItem(
              "defaultAssetCategory",
              defaultAssetCategory
            );

            if (accountType === 1 || accountType === "1") {
              window.localStorage.setItem("accessJobbing", "true");
            } else {
              window.localStorage.setItem("accessJobbing", "false");
            }
            if (
              window.location.pathname.includes("/profile") ||
              window.location.pathname.includes("/settings")
            ) {
              console.log("this is test 2");
              window.location.reload();
            }
          }
        }
      }
    });
};

export const apiResendOTP = async (data) => {
  return axios
    .post(`/api/user/resend-verification-code/0`, data)
    .then((result) => {
      if (result && result.data) {
        return true;
      }
      return false;
    });
};

export const apiVerifyAccount = async (data) => {
  return axios.post(`/api/user/verify`, data).then((result) => {
    if (result && result.data) {
      return true;
    }
    return false;
  });
};

export const apiVerifyToken = async (data) => {
  return axios
    .post(`/api/user/validate-otp/forgot-password`, data)
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiSendContactForm = async (data) => {
  return axios.post(`/api/user/send-contact-form`, data).then((result) => {
    if (result && result.data) {
      return result.data;
    }
    return false;
  });
};

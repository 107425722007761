import axios from "axios";

export const apiGetHosePositions = (equipmentTypeName) => {
  return axios
    .get(`/api/Jobbing/hose/positions/${equipmentTypeName}`)
    .then((result) => {
      if (result) {
        if (result.data) {
          if (result.data.positions) {
            result.data.positions.map((item) => {
              item.text = item.name;
              item.value = item.id;
            });
            return result.data;
          }
        }
      }
      return result.data;
    });
};

export const apiGetProduct = (productName) => {
  const data = {
    SearchParam: productName ? productName : null,
  };
  return axios.get(`/api/Products/`, { params: data }).then((result) => {
    if (result) {
      if (result.data) {
        result.data.records[0].stockImage1 = result.data.records[0].image128;
        result.data.records[0].stockDescription = result.data.records[0].name;
        return result.data.records[0];
      }
    }
    return false;
  });
};

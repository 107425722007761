export const hoseMakerTempData = {
  step1_Hose: 0,
  step1_Barcode: "",
  step1_Name: "",
  step2_LHF: 0,
  step2_Barcode: "",
  step2_Name: "",
  step3_LFerrule: 0,
  step3_Barcode: "",
  step3_Name: "",
  oAL: null,
  oalString: "",
  others: [],
};

import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Grid,
  Icon,
  Image,
  Input,
  Button,
  Header,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import "./hoseMaker.css";
import MetaData from "../../components/MetaData";
import rightArrow from "../../../src/assets/images/right-transparent.png";
import "../Search/narrowFilters.css";
import SearchComponent from "../../containers/Search";
import { apiGetProduct } from "./hoseMaker.api";
import NumericInputBox from "../../components/NumericInputBox";
import _ from "lodash";
import productDefaultImage from "../../assets/images/product_default.png";
import { hoseMakerTempData } from "./data";
import { showErrorToast } from "../../utils/toastUtils";
import noImageAvailable from "../../assets/images/no_image_available.png";

const HoseMakerDT = (props) => {
  const { currentStatus, isAssemble } = props;
  const [formData, setFormData] = useState({
    step1_Hose: 0,
    step1_Barcode: "",
    step1_Name: "",
    step2_LHF: 0,
    step2_Barcode: "",
    step2_Name: "",
    step3_LFerrule: 0,
    step3_Barcode: "",
    step3_Name: "",
    oAL: null,
    oalString: "",
    otherItems: [],
  });
  const [selectedHose, setSelectedHose] = useState({});
  const [selectedFitting, setSelectedFitting] = useState({});
  const [selectedFitting2, setSelectedFitting2] = useState({});
  const [selectedFerrule, setSelectedFerrule] = useState({});
  const [isLHFerrule, setIsLHFerrule] = useState(false);
  const [step, setStep] = useState(1);
  const [viewStep, setViewStep] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState("");
  const [otherItemsList, setOtherItemsList] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchVisible, setSearchVisible] = useState(true);

  const onConfirmBOM = () => {
    if (props.hoseMakerIndex === null) {
      props.addBOM(formData);
    } else {
      props.addBOM(formData);
    }

    setFormData({});
    props.onCancelHoseMaker();
  };

  function handleChange(dataType, value, index = 0) {
    setIsLoading(true);

    let newState = [];
    newState.push(formData);
    let hoseData = newState.map((item, i) => {
      if (i == 0) {
        if (dataType === "hose") {
          item.step1_Hose = value.id;
          item.step1_Barcode = value.stockCode;
          item.step1_Name = value.stockDescription;
          return item;
        }
        if (dataType === "fitting1") {
          item.step2_LHF = value.id;
          item.step2_Barcode = value.stockCode;
          item.step2_Name = value.stockDescription;
          return item;
        }
        if (dataType === "ferrule1") {
          item.step3_LFerrule = value.id;
          item.step3_Barcode = value.stockCode;
          item.step3_Name = value.stockDescription;
          return item;
        }
        if (dataType === "oAL") {
          item.oAL = Number(value);
          item.oalString = value;
          return item;
        }
        return { ...item, [dataType]: value };
      }

      return item;
    });

    console.log(hoseData[0]);
    setFormData(hoseData[0]);
    setTimeout(function () {
      setIsLoading(false);
    }, 300);
  }

  async function handleBOMQuantityChange(index, value) {
    await setOtherItemsList([]);
    let newState = [];
    newState.push(formData);
    let hoseData = newState.map((item, i) => {
      if (i == 0) {
        item.otherItems[index].quantity = value;
      }
      // if (value == 0) {
      //   item.otherItems.splice(index, 1);
      // }
      return item;
    });

    setFormData(hoseData[0]);
    setOtherItemsList(hoseData[0].otherItems);
  }

  const addOtherItems = async (product) => {
    setSearchVisible(false);
    setIsLoading(true);
    await setOtherItemsList([]);
    const productData = {
      productId: product.id,
      quantity: 1,
      stockCode: product.stockCode,
      stockDescription: product.stockDescription,
      productName: product.stockDescription,
      productSKU: product.stockCode,
      stockImage1: product.stockImage1,
      name: product.stockDescription,
      barcode: product.stockCode,
    };

    let newState = formData.otherItems;
    const existing = newState.find(
      (element) => element.productId === product.id
    );

    if (!existing) newState.push(productData);

    setOtherItemsList(newState);
    window.scrollTo(0, 0);
    setIsLoading(false);
    setTimeout(() => {
      setSearchVisible(true);
    }, 200);
  };

  const onHoseCheck = (hose) => {
    if (hose) {
      setSelectedHose(hose);
      if (props.hoseMakerIndex === null) {
        setStep(2);
        setViewStep(2);
        handleChange("hose", hose);
      }
    }
  };

  const onHoseClick = (hose) => {
    if (hose) {
      setSelectedHose(hose);
      if (formData.oAL === null) setStep(2);
      setViewStep(2);
      handleChange("hose", hose);
    }
  };

  const onFittingCheck = (fitting) => {
    if (fitting) {
      if (fitting.category.name.includes("(2-PC)")) setIsLHFerrule(true);
      setSelectedFitting(fitting);

      if (props.hoseMakerIndex === null) {
        if (fitting.category.name.includes("(2-PC)")) {
          if (
            formData?.step3_LFerrule !== null &&
            formData?.step3_LFerrule !== 0
          ) {
            setStep(4);
            setViewStep(4);
          } else {
            if (formData.oAL === null) {
              setStep(3.1);
              setViewStep(3.1);
            }
          }
        } else {
          setIsLHFerrule(false);
          setStep(4);
          setViewStep(4);
        }
        handleChange("fitting1", fitting);
      }
    }
  };

  const onFittingClick = (fitting) => {
    if (fitting) {
      if (fitting.category.name.includes("(2-PC)")) setIsLHFerrule(true);
      setSelectedFitting(fitting);

      if (fitting.category.name.includes("(2-PC)")) {
        if (formData.oAL === null) setStep(2.1);
        setViewStep(3.1);
      } else {
        setIsLHFerrule(false);
        if (formData.oAL === null) setStep(4);
        setViewStep(4);
      }
      handleChange("fitting1", fitting);
    }
  };

  const onFerruleClick = (ferrule) => {
    if (ferrule) {
      setSelectedFerrule(ferrule);
      if (formData.oAL === null) setStep(4);
      setViewStep(4);
      handleChange("ferrule1", ferrule);
    }
  };

  useEffect(() => {
    if (viewStep >= 1) {
      const container = document.getElementById("scroll-container");
      container.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [viewStep]);

  const getProduct = async (type, productName) => {
    if (productName === null || productName === "") return false;
    const product = await apiGetProduct(productName);
    if (type === "hose") {
      onHoseCheck(product);
    }
    if (type === "fitting1") {
      onFittingCheck(product);
    }
  };

  useEffect(() => {
    const selectedData = props.hoseMakerData;
    console.log(selectedData);
    if (
      selectedData?.hoseProductId ||
      selectedData?.fittingProductId ||
      selectedData?.oAL ||
      selectedData?.otherItems?.length > 0
    ) {
      props.hoseMakerData.oalString = props.hoseMakerData.oAL?.toString();

      selectedData.step1_Hose = selectedData.hoseProductId;
      selectedData.step1_Barcode = selectedData.hoseSku;
      selectedData.step1_Name = selectedData.hoseName;
      selectedData.step2_LHF = selectedData.fittingProductId;
      selectedData.step2_Barcode = selectedData.fittingSku;
      selectedData.step2_Name = selectedData.fittingName;
      selectedData.step3_LFerrule = selectedData.ferruleProductId;
      selectedData.step3_Barcode = selectedData.ferruleSku;
      selectedData.step3_Name = selectedData.ferruleProductName;
      selectedData.oalString = selectedData.oAL?.toString();

      console.log(selectedData);

      if (selectedData.otherItems !== null) {
        const otherItems = selectedData.otherItems?.map((data) => {
          data.stockCode = data.productSKU;
          data.stockDescription = data.productName;
          data.stockImage1 = data.image128
            ? data.image128
            : data.productImage
            ? data.productImage
            : null;
          return data;
        });

        setOtherItemsList(otherItems);
      }

      setViewStep(1);

      if (selectedData.oAL !== null) {
        getProduct("hose", selectedData.step1_Barcode);
        getProduct("fitting1", selectedData.step2_Barcode);
        if (selectedData.oAL) {
          setStep(4);
        }
        setFormData(selectedData);
      } else if (
        selectedData?.step3_LFerrule !== null &&
        selectedData?.step3_LFerrule !== 0
      ) {
        getProduct("hose", selectedData.step1_Barcode);
        getProduct("fitting1", selectedData.step2_Barcode);
        setStep(4);
        setFormData(selectedData);
      } else if (
        selectedData?.step2_LHF !== null &&
        selectedData?.step2_LHF !== 0
      ) {
        getProduct("hose", selectedData.step1_Barcode);
        getProduct("fitting1", selectedData.step2_Barcode);
        setStep(2);
        setFormData(selectedData);
      } else if (
        selectedData?.step1_Hose !== null &&
        selectedData?.step1_Hose !== 0
      ) {
        getProduct("hose", selectedData.step1_Barcode);
        setStep(2);
        setFormData(selectedData);
      } else if (selectedData?.otherItems?.length > 0) {
        setFormData(selectedData);
      } else {
        setTimeout(function () {
          setFormData(hoseMakerTempData);
        }, 500);
      }
    } else {
      setFormData({
        step1_Hose: 0,
        step1_Barcode: "",
        step1_Name: "",
        step2_LHF: 0,
        step2_Barcode: "",
        step2_Name: "",
        step3_LFerrule: 0,
        step3_Barcode: "",
        step3_Name: "",
        oAL: null,
        oalString: "",
        otherItems: [],
      });
    }
  }, [props.hoseMakerData]);

  return (
    <React.Fragment>
      <MetaData title="Job Management" />
      <div className="hose-maker">
        <Grid>
          <Grid.Column width={3}>
            <h3>
              <Icon name="wrench" /> Hose Assembly
            </h3>
          </Grid.Column>
          <Grid.Column
            width={10}
            className="job-list-section"
            style={{ paddingRight: 0 }}
          >
            <div class="arrow-steps clearfix">
              <div
                className={
                  viewStep === 0 || viewStep === 1 ? "step current" : "step"
                }
                onClick={() => setViewStep(1)}
              >
                <span>
                  <a>Select Hose</a>
                </span>
              </div>
              <div
                className={viewStep === 2 ? "step current" : "step"}
                style={{ width: 130, paddingRight: 10, textAlign: "right" }}
                onClick={() => setViewStep(2)}
              >
                <span>
                  <a>Set hose length</a>
                </span>
              </div>
              <div
                className={viewStep === 3 ? "step current" : "step"}
                style={{ width: 130, paddingRight: 10, textAlign: "right" }}
                onClick={() => setViewStep(3)}
              >
                <span>
                  <a>Select first fitting</a>
                </span>
              </div>
              {isLHFerrule ? (
                <div
                  className={viewStep === 3.1 ? "step current" : "step"}
                  style={{ width: 110, paddingRight: 10, textAlign: "right" }}
                  onClick={() => setViewStep(3.1)}
                >
                  <span>
                    <a>Select ferrule</a>
                  </span>
                </div>
              ) : null}
              <div
                className={viewStep === 4 ? "step current" : "step"}
                style={{ width: 250, paddingRight: 5, textAlign: "center" }}
                onClick={() => setViewStep(4)}
              >
                <span>
                  <a>Select hose assembly accessories</a>
                </span>
              </div>
            </div>
          </Grid.Column>
        </Grid>
        <Grid columns={3} divided>
          <Grid.Column
            computer={3}
            tablet={6}
            mobile={16}
            className="job-list-section"
          >
            <Grid disabled={currentStatus > 2}>
              <Grid.Column
                width={16}
                style={{ paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}
              >
                <div className="hose-actions">
                  <Button
                    size="small"
                    onClick={() => props.onCancelHoseMaker()}
                  >
                    {props.hoseMakerData.isPublic ? "Close" : "Cancel"}
                  </Button>
                  {!props.hoseMakerData.isPublic && (
                    <Button
                      className="active-status"
                      size="small"
                      onClick={() => onConfirmBOM()}
                      style={{
                        display: currentStatus > 2 ? "none" : "visible",
                      }}
                    >
                      Confirm
                    </Button>
                  )}
                </div>
              </Grid.Column>
              <Grid.Column width={16}>
                <Header
                  as="h4"
                  style={{
                    textDecoration: "underline",
                    textUnderlineOffset: 5,
                  }}
                >
                  Hose End #{props.hoseNumber ? props.hoseNumber + 1 : 1}
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            computer={10}
            tablet={10}
            mobile={16}
            style={{ paddingRight: 0, paddingTop: 0 }}
          >
            <Grid style={{ marginTop: 0 }}>
              <div className="hose-maker-container">
                <div className="hose-maker-scroll" id="scroll-container">
                  {viewStep == 1 ? (
                    <div disabled={currentStatus > 2}>
                      <Grid.Column width={16}>
                        <div id="step1Id">
                          <Header
                            as="h4"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: 5,
                            }}
                          >
                            1. Select hose type and size
                          </Header>
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        disabled={props.hoseMakerData.isPublic}
                        width={16}
                        style={{ padding: "0px 20px" }}
                      >
                        <SearchComponent
                          isHoseMaker={true}
                          onProductClick={onHoseClick}
                          module={"Hydraulic Hose"}
                        />
                      </Grid.Column>
                    </div>
                  ) : null}
                  {viewStep === 2 ? (
                    <div disabled={currentStatus > 2}>
                      <Grid.Column width={16}>
                        <div id="step3Id">
                          <Header
                            as="h4"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: 5,
                            }}
                          >
                            2. Set hose length
                          </Header>
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        disabled={props.hoseMakerData.isPublic}
                        width={16}
                        style={{
                          padding: 20,
                          paddingTop: 50,
                          minHeight: 550,
                        }}
                      >
                        <Grid>
                          <Grid.Column width={5} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginLeft: "auto",
                                borderColor: "rgb(102, 204, 35)",
                                height: 120,
                                width: 130,
                              }}
                            >
                              {selectedFitting &&
                              selectedFitting.stockImage1 ? (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={`data:image/jpeg;base64,${selectedFitting.stockImage1}`}
                                />
                              ) : (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={noImageAvailable}
                                />
                              )}
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={6}
                            style={{ padding: 0, zIndex: 1001 }}
                          >
                            <div
                              style={{
                                marginTop: 22,
                                borderTop: "17px solid #000",
                                borderBottom: "17px solid #000",
                                paddingTop: 8,
                                paddingBottom: 8,
                                textAlign: "center",
                              }}
                            >
                              <span style={{ fontSize: 12 }}>
                                {selectedHose.stockDescription}
                              </span>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={5}
                            style={{ padding: 0 }}
                          ></Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{ padding: 0, marginTop: 5 }}
                          >
                            <div
                              id="step4Id"
                              style={{ width: 500, margin: "auto" }}
                            >
                              <Header
                                as="h4"
                                style={{
                                  textUnderlineOffset: 5,
                                  textAlign: "center",
                                }}
                              >
                                Enter Overall length (OAL)
                              </Header>
                            </div>
                            <div
                              style={{
                                width: 250,
                                margin: "auto",
                                marginTop: 15,
                              }}
                            ></div>
                            <div
                              style={{
                                width: 250,
                                margin: "auto",
                                marginTop: 0,
                                paddingRight: 10,
                              }}
                            >
                              <Input
                                type="number"
                                label={{
                                  basic: true,
                                  content: "mm",
                                }}
                                min={0}
                                labelPosition="right"
                                style={{ width: "82%" }}
                                value={formData.oalString}
                                onChange={(e, data) =>
                                  handleChange("oAL", data.value)
                                }
                              />
                            </div>
                            <div
                              style={{
                                width: 250,
                                margin: "auto",
                                marginTop: 25,
                              }}
                            >
                              <Button
                                className="active-status"
                                size="small"
                                style={{
                                  width: "100%",
                                  backgroundColor: "rgb(102, 204, 35)",
                                  color: "#fff",
                                  marginLeft: "auto",
                                }}
                                onClick={() => {
                                  setStep(3);
                                  setViewStep(3);
                                }}
                              >
                                Next
                              </Button>
                            </div>
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </div>
                  ) : null}
                  {viewStep == 3 ? (
                    <div disabled={currentStatus > 2}>
                      <Grid.Column width={16}>
                        <div id="step2Id">
                          <Header
                            as="h4"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: 5,
                            }}
                          >
                            3. Select hose fitting
                          </Header>
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        disabled={props.hoseMakerData.isPublic}
                        width={16}
                        style={{
                          padding: 20,
                          paddingTop: 50,
                          minHeight: 550,
                        }}
                      >
                        <Grid>
                          <Grid.Column width={5} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginLeft: "auto",
                                borderStyle: "dashed",
                                borderColor: "rgb(102, 204, 35)",
                                height: 120,
                                width: 130,
                              }}
                            >
                              {selectedFitting &&
                              selectedFitting.stockImage1 ? (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={`data:image/jpeg;base64,${selectedFitting.stockImage1}`}
                                />
                              ) : null}
                            </div>
                          </Grid.Column>
                          <Grid.Column width={6} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginTop: 22,
                                borderTop: "17px solid #000",
                                borderBottom: "17px solid #000",
                                paddingTop: 8,
                                paddingBottom: 8,
                                textAlign: "center",
                              }}
                            >
                              <span style={{ fontSize: 12 }}>
                                {selectedHose.stockDescription}
                              </span>
                            </div>
                          </Grid.Column>
                          <Grid.Column width={5}></Grid.Column>
                          <Grid.Column width={16} style={{ zIndex: 1002 }}>
                            <div
                              className="job-status"
                              style={{
                                paddingRight: "0px !important",
                                width: "100%",
                                margin: "auto",
                              }}
                            >
                              <Button
                                className={status === "1" && "active-status"}
                                size="medium"
                                onClick={() => {
                                  setStatus("1");
                                  setSearchTerm("Straight");
                                }}
                              >
                                Straight
                              </Button>
                              <Button
                                className={status === "2" && "active-status"}
                                size="medium"
                                onClick={() => {
                                  setStatus("2");
                                  setSearchTerm("90° swept");
                                }}
                              >
                                90° swept
                              </Button>
                              <Button
                                className={status === "3" && "active-status"}
                                size="medium"
                                onClick={() => {
                                  setStatus("3");
                                  setSearchTerm("90° compact");
                                }}
                              >
                                90° compact
                              </Button>
                              <Button
                                className={status === "4" && "active-status"}
                                size="medium"
                                onClick={() => {
                                  setStatus("4");
                                  setSearchTerm("45° swept");
                                }}
                              >
                                45° swept
                              </Button>
                              <Button
                                className={status === "5" && "active-status"}
                                size="medium"
                                onClick={() => {
                                  setStatus("5");
                                  setSearchTerm("45° compact");
                                }}
                              >
                                45° compact
                              </Button>
                              {/* <Button
                                className={status === "6" && "active-status"}
                                size="medium"
                                onClick={() => setStatus("6")}
                              >
                                Other
                              </Button> */}
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{ padding: "0px 20px" }}
                          >
                            <SearchComponent
                              isHoseMaker={true}
                              onProductClick={onFittingClick}
                              module={"Hydraulic Fittings"}
                              searchTerm={searchTerm}
                              marginTop={-260}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </div>
                  ) : null}
                  {viewStep == 3.1 ? (
                    <div disabled={currentStatus > 2}>
                      <Grid.Column width={16}>
                        <div id="step2Id">
                          <Header
                            as="h4"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: 5,
                            }}
                          >
                            3.1 Select ferrule
                          </Header>
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        disabled={props.hoseMakerData.isPublic}
                        width={16}
                        style={{
                          padding: 20,
                          paddingTop: 50,
                          minHeight: 550,
                        }}
                      >
                        <Grid>
                          <Grid.Column width={5} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginLeft: "auto",
                                borderStyle: "dashed",
                                borderColor: "rgb(102, 204, 35)",
                                height: 120,
                                width: 130,
                              }}
                            >
                              {selectedFitting &&
                              selectedFitting.stockImage1 ? (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={`data:image/jpeg;base64,${selectedFitting.stockImage1}`}
                                />
                              ) : (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={noImageAvailable}
                                />
                              )}
                            </div>
                          </Grid.Column>
                          <Grid.Column width={6} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginTop: 22,
                                borderTop: "17px solid #000",
                                borderBottom: "17px solid #000",
                                paddingTop: 8,
                                paddingBottom: 8,
                                textAlign: "center",
                              }}
                            >
                              <span style={{ fontSize: 12 }}>
                                {selectedHose.stockDescription}
                              </span>
                            </div>
                          </Grid.Column>
                          <Grid.Column width={5}></Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{ padding: "0px 20px" }}
                          >
                            <SearchComponent
                              isHoseMaker={true}
                              onProductClick={onFerruleClick}
                              module={"Hydraulic Ferrules"}
                              marginTop={-260}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </div>
                  ) : null}
                  {viewStep === 4 ? (
                    <div disabled={currentStatus > 2}>
                      <Grid.Column width={16}>
                        <div id="step3Id">
                          <Header
                            as="h4"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: 5,
                            }}
                          >
                            4. Select lengths and accessories
                          </Header>
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        disabled={props.hoseMakerData.isPublic}
                        width={16}
                        style={{
                          padding: 20,
                          paddingTop: 50,
                          minHeight: 550,
                        }}
                      >
                        <Grid>
                          <Grid.Column
                            width={16}
                            style={{
                              padding: "0px 17px",
                              marginBottom: 15,
                            }}
                          >
                            <div
                              style={{
                                marginTop: 25,
                                marginBottom: 10,
                              }}
                            >
                              <Header
                                as="h4"
                                style={{
                                  textUnderlineOffset: 5,
                                  textAlign: "center",
                                }}
                              >
                                Other items:
                              </Header>
                            </div>
                            {otherItemsList?.length > 0 ? (
                              <div>
                                {otherItemsList.map((data, index) => {
                                  if (data.quantity === 0) {
                                    return null;
                                  } else {
                                    return (
                                      <div key={index}>
                                        <Card
                                          style={{
                                            width: "100%",
                                          }}
                                          fluid
                                          color="green"
                                        >
                                          <Card.Content>
                                            <Grid>
                                              <Grid.Column width={12}>
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  {`${
                                                    data.productSKU
                                                      ? data.productSKU
                                                      : data.barcode
                                                  } - ${
                                                    data.productName
                                                      ? data.productName
                                                      : data.name
                                                  }`}
                                                </span>
                                                <Grid>
                                                  <Grid.Column width={5}>
                                                    <div
                                                      style={{
                                                        width: 75,
                                                        height: 75,
                                                      }}
                                                    >
                                                      <Image
                                                        style={{
                                                          width: 75,
                                                          height: 75,
                                                        }}
                                                        src={
                                                          data.stockImage1
                                                            ? `data:image/jpeg;base64,${data.stockImage1}`
                                                            : productDefaultImage
                                                        }
                                                      />
                                                    </div>
                                                  </Grid.Column>
                                                  <Grid.Column
                                                    width={11}
                                                    style={{
                                                      paddingTop: 35,
                                                    }}
                                                  ></Grid.Column>
                                                </Grid>
                                              </Grid.Column>
                                              <Grid.Column width={4}>
                                                <Form
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <label>Quantity</label>
                                                  <Form.Field>
                                                    <NumericInputBox
                                                      value={data.quantity}
                                                      name="quantity"
                                                      min={0}
                                                      max={10000}
                                                      maxLength={6}
                                                      precision={0}
                                                      step={1}
                                                      onChangeHandler={(
                                                        value
                                                      ) =>
                                                        handleBOMQuantityChange(
                                                          index,
                                                          value
                                                        )
                                                      }
                                                      onKeyDownHandler={(e) => {
                                                        if (e.key === "Enter")
                                                          e.preventDefault();
                                                      }}
                                                    />
                                                  </Form.Field>
                                                </Form>
                                              </Grid.Column>
                                            </Grid>
                                          </Card.Content>
                                        </Card>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            ) : null}
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{ padding: "0px 20px" }}
                          >
                            {searchVisible && (
                              <SearchComponent
                                isHoseMaker={true}
                                onProductClick={addOtherItems}
                              />
                            )}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </div>
                  ) : null}
                </div>
              </div>
            </Grid>
          </Grid.Column>
          <Grid.Column
            computer={3}
            tablet={16}
            mobile={16}
            style={{ paddingRight: 0, paddingTop: 0 }}
          >
            <div className="hose-maker-container">
              <div className="hose-maker-scroll">
                <Grid disabled={currentStatus > 2}>
                  <Grid.Column width={2} style={{ paddingRight: 0 }}>
                    <Image
                      width={18}
                      src={rightArrow}
                      wrapped
                      style={{ marginTop: -2 }}
                    />
                  </Grid.Column>
                  <Grid.Column width={14}>
                    <Header as="h5" style={{ marginTop: 2 }}>
                      Bill of Materials
                    </Header>
                  </Grid.Column>
                  <Grid.Column
                    width={16}
                    style={{ marginTop: -5, paddingTop: 0 }}
                  >
                    {formData && formData.step1_Hose ? (
                      <div>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          Hose:
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {`${formData.step1_Barcode} \xa0  x \xa0  1`}
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {formData.step1_Name}
                        </span>
                      </div>
                    ) : null}
                    {formData && formData.step2_LHF ? (
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          Fitting:
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {`${formData.step2_Barcode} \xa0  x \xa0  1`}
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {formData.step2_Name}
                        </span>
                      </div>
                    ) : null}
                    {formData && formData.step3_LFerrule ? (
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          Ferrule:
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {`${formData.step3_Barcode} \xa0  x \xa0  1`}
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {formData.step3_Name}
                        </span>
                      </div>
                    ) : null}
                    {formData && formData.step4_RHF ? (
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          RH Fitting:
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {`${formData.step4_Barcode} \xa0  x \xa0  1`}
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {formData.step4_Name}
                        </span>
                      </div>
                    ) : null}
                    {formData && formData.step5_RFerrule ? (
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          RH Ferrule:
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {`${formData.step5_Barcode} \xa0  x \xa0  1`}
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {formData.step5_Name}
                        </span>
                      </div>
                    ) : null}
                    {formData && formData.oAL ? (
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          OAL:
                        </span>
                        <br />
                        <span
                          style={{ fontSize: 12 }}
                        >{`${formData.oAL}mm`}</span>
                      </div>
                    ) : null}
                    {formData && formData.orientation ? (
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          Orientation:
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {formData.orientation}degrees
                        </span>
                      </div>
                    ) : null}
                    {formData &&
                    formData.otherItems &&
                    formData.otherItems?.length > 0 ? (
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          Other items:
                        </span>
                        {formData?.otherItems?.map((data, index) => {
                          return (
                            <div key={index}>
                              <span style={{ fontSize: 12 }}>
                                {`${
                                  data.productSKU
                                    ? data.productSKU
                                    : data.barcode
                                } \xa0  x \xa0  ${data.quantity}`}
                              </span>
                              <br />
                              <span style={{ fontSize: 12 }}>
                                {data.productName
                                  ? data.productName
                                  : data.name}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </Grid.Column>
                </Grid>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default HoseMakerDT;
